*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}

body{
  background-color: #41286C;
}

@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat/static/Montserrat-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat/static/Montserrat-Italic.ttf);
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat/static/Montserrat-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat/static/Montserrat-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat/static/Montserrat-Bold.ttf);
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat/static/Montserrat-BlackItalic.ttf);
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

header {
  background-color: #FFF;
  width: 100%;
}

header .header{
  max-width: 1280px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: 0 auto;
}

header .header .logo-field{
  display: block;
}

header .header .logo-field .logo-img{
  height: 80px;
}

header .header h1{
  font-size: 26px;
  font-weight: bold;
  text-transform: uppercase;
  color: #41286C;
}

header .header .painel-link{
  font-size: 20px;
  font-weight: 600;
  text-align: end;
  display: block;
  margin-top: 10px;
  color: rgb(76, 75, 71);
}

section.content{
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px;
  margin: 0 auto;
}

section.content .text{
  width: 60%;
}

section.content .text .controlField{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

section.content .text .controlField .steps{
  margin: 0;
}

section.content .text .controlField .controlButton{
  width: 120px;
  padding: 14px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: transparent;
  border: 2px solid #FFF;
  cursor: pointer;
}

section.content .text h2{
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
  text-transform: uppercase;
  color: #FFF;
  margin-bottom: 20px;
}

section.content .text p{
  font-size: 16px;
  line-height: 26px;
  color: #FFF;
  margin-bottom: 20px;
}

section.content .text .form-control{
  font-size: 14px;
  padding: 12px;
  width: 100%;
  display: block;
  background-color: #FFF;
  border: 1px solid #FFF;
  margin-bottom: 10px;
  font-weight: 500;
  color: #4C4B47;
  outline: none;
}

section.content .text .form-control::placeholder{
  color: #41286C;
  font-weight: bold;
}

section.content .text .form-control:disabled{
  background-color: #D4D4D4;
}

section.content .text .form-button{
  background-color: #41286C;
  font-size: 14px;
  padding: 12px;
  width: 100%;
  color: #FFF;
  font-weight: bold;
  border: 2px solid #FFF;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 30px;
}

.activeChip{
  background-color: #41286C;
  font-size: 14px;
  padding: 12px;
  width: 100%;
  color: #FFF;
  font-weight: bold;
  border: 2px solid #FFF;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 30px;
  display: block;
  width: fit-content;
  text-decoration: none;
}

.loading-field{
  background-color: #ffffffbb;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  border: 5px solid #FFF;
  border-radius: 50%;
  border-top: 5px solid #41286C;
  border-bottom: 5px solid #4c4b47;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media(max-width: 758px){
  header .header .logo-field .logo-img{
    height: 50px;
  }
  
  header .header h1{
    display: none;
  }

  header .header .painel-link{
    font-size: 16px;
  }

  section.content .text{
    width: 100%;
  }

  section.content .telefone-img{
    display: none;
  }

  section.content .text h2{
    font-size: 36px;
    line-height: 46px;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFF;
    margin-bottom: 20px;
  }
}